// @flow
import React from 'react';
import { useFela, } from 'react-fela';
import { useInView, } from 'react-intersection-observer';
import type { ComponentPropResponsiveObject, } from '@haaretz/htz-css-tools';
import type { ListViewProps, } from '../../../../flowTypes/ListViewProps';

import setBiAction from '../../../../utils/setBiAction';
import setBiImpression from '../../../../utils/setBiImpression';

import ListView from '../../../ListView/NewListView';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';
import Section from '../../../AutoLevels/Section';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import Image from '../../../Image/Image';
import getImageAssets from '../../../../utils/getImageAssets';
import TeaserContent from '../../../TeaserContent/NewTeaserContent';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserFooter from '../../../TeaserFooter/TeaserFooter';
import { BroadcastMobileListViewHeader, } from '../Broadcast1/Broadcast1ListViewHeader';
import Paragraph from '../../../Paragraph/Paragraph';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';

type Props = ListViewProps & {
  onTeaserView: ?(TeaserDataType => void),
};

Broadcast2View.defaultProps = {
  sectionId: null,
  isLazyloadImages: true,
  onTeaserView: null,
};

const teasers = [
  Broadcast2Teaser,
  Broadcast2TeaserNoImage,
  Broadcast2Teaser,
  Broadcast2TeaserShowTldr,
  Broadcast2TeaserNoImageHideTldr,
  Broadcast2TeaserNoImageShowTldr,
  Broadcast2TeaserNoImageShowTldr, ];

export default function Broadcast2View({
  isLazyloadImages,
  list,
  onTeaserView,
  biAction,
  biImpression,
}: Props) {
  const { items, title, description, } = list;
  const { theme, } = useFela();

  if (!items) return null;

  const colTemplate = [
    { from: 'l', value: 'auto 80rem', },
  ];

  const teaserGridArea = [
    { from: 'l', value: 'auto/2', },
  ];

  const itemsToDisplay = items.filter(Boolean);

  return (
    <ListView
      colTemplate={colTemplate}
      rowGap="1.5rem"
      colGap="4rem"
      gridGap={null}
      padding={[ { until: 's', value: '0', }, ]}
      marginTop="0"
    >
      {title ? (
        <>
          <ListViewHeader
            title={title}
            theme={theme}
            disableGridArea
            miscStyles={{
              display: [ { until: 's', value: 'none', }, ],
              flexDirection: 'column',
              gridRowEnd: [ { from: 'l', value: `span ${itemsToDisplay.length}`, }, ],
            }}
            description={description}
            descriptionMiscStyles={{ ...theme.type(0, { fromBp: 's', untilBp: 'l', }), }}
          />
          <BroadcastMobileListViewHeader
            title={title}
            description={description}
            miscStyles={description ? null : { marginBottom: '-4rem', }}
            iconName="iconAvatar"
          />
        </>
      ) : null}
      <Section isFragment>
        {itemsToDisplay.map((item, i,) => {
          const teaserIdx = i % 7;
          const TeaserItem = teasers[teaserIdx];
          return (
            <TeaserItem
              key={item.contentId}
              item={item}
              isLazyloadImages={isLazyloadImages}
              theme={theme}
              gridArea={teaserGridArea}
              biAction={setBiAction(i, item, biAction)}
              onView={() => {
                onTeaserView && onTeaserView(item);
                setBiImpression(i, item, biImpression)();
              }}
            />
          );
        })}
      </Section>
    </ListView>
  );
}

// /////////////////////////////////////////////////////////////////////
//                              TEASERS                               //
// /////////////////////////////////////////////////////////////////////

type TeaserPropTypes = {
  item: TeaserDataType,
  gridArea?: ?(string | ComponentPropResponsiveObject<string>[]),
  isLazyloadImages: boolean,
  theme: Object,
  showImage: boolean,
  showTldr: boolean,
  onView: () => void,
  biAction: ?() => void,
};

Broadcast2Teaser.defaultProps = {
  isLazyloadImages: true,
  theme: null,
  gridArea: null,
  showImage: true,
  showTldr: false,
  biAction: null,
};


function Broadcast2Teaser({
  item,
  isLazyloadImages,
  showImage,
  showTldr,
  gridArea,
  theme,
  onView,
  biAction,
}: TeaserPropTypes) {
  const [ ref, inView, ] = useInView({ threshold: 0.3, triggerOnce: true, });
  const [ wasInView, setWasInView, ] = React.useState(false);

  React.useEffect(() => {
    if (wasInView) return;
    if (inView) {
      onView();
      setWasInView(true);
    }
  }, [ inView, wasInView, onView, ]);

  if (!item) return null;
  const areasTemplate = `
  " . . . "
  " . content . "
  "  . media . "
  " . tldr ."`;

  const colTemplate = [
    { until: 's', value: '2rem auto 2rem', },
    { from: 's', until: 'l', value: '4rem auto 4rem', },
    { from: 'l', value: '3rem auto 3rem', },
  ];

  const rowTemplate = [
    { until: 's', value: '2rem auto auto auto', },
    { from: 's', until: 'l', value: '3rem auto auto auto', },
    { from: 'l', value: '2rem auto auto auto', },
  ];

  const imgOptions = {
    bps: theme.bps,
    sizes: [
      { from: 'xl', size: '550px', },
      { from: 'l', size: '460px', },
      { from: 'm', size: '708px', },
      { from: 's', size: '540px', },
      { size: 'calc(100vw - 46px)', },
    ],
    aspect: 'regular',
    widths: [ 708, 550, 460, 340, 250, ],
  };

  const { image, tldr, } = item;
  const addTldr = showTldr && tldr;

  return (
    <Teaser
      fwRef={ref}
      areasTemplate={areasTemplate}
      colTemplate={colTemplate}
      rowTemplate={rowTemplate}
      miscStyles={{
        gridArea,
      }}
    >
      {showImage
        ? (
          <TeaserMedia
            data={item}
            onClick={biAction}
            miscStyles={{
              marginBottom: [
                { until: 's', value: '2rem', },
                { from: 's', until: 'l', value: addTldr ? '2rem' : '4rem', },
                { from: 'l', value: addTldr ? '2rem' : '3rem', }, ],
            }}
          >
            <Image
              image={image}
              imgOptions={getImageAssets(imgOptions)}
              lazyLoad={isLazyloadImages}
            />
          </TeaserMedia>
        ) : null}
      <TeaserContent>
        <TeaserHeader
          {...item}
          typeScale={1}
          onClick={biAction}
        />
        <TeaserFooter
          showAuthor
          data={item}
          showCommentsCount={false}
          miscStyles={{
            marginTop: '1rem',
            marginBottom: '2rem',
          }}
        />
      </TeaserContent>
      {addTldr ? <Broadcast2Tldr tldr={tldr} /> : null}
    </Teaser>
  );
}

function Broadcast2TeaserNoImage(props) {
  return <Broadcast2Teaser showImage={false} {...props} />;
}

function Broadcast2TeaserShowTldr(props) {
  return <Broadcast2Teaser showTldr {...props} />;
}

function Broadcast2TeaserNoImageHideTldr(props) {
  return <Broadcast2Teaser showImage={false} showTldr={false} {...props} />;
}

function Broadcast2TeaserNoImageShowTldr(props) {
  return <Broadcast2Teaser showImage={false} showTldr {...props} />;
}

// /////////////////////////////////////////////////////////////////////
//                             TLDR                                   //
// /////////////////////////////////////////////////////////////////////

type TldrProps ={
  tldr: Array<JSON>,
};

Broadcast2Tldr.defaultProps = { tldr: [], };

function Broadcast2Tldr({ tldr, }: TldrProps) {
  const { css, theme, } = useFela();
  return (
    <>
      <div className={css({
        gridArea: 'tldr',
        extend: [
          theme.type(0, { until: 's', }),
          theme.type(1, { from: 's', until: 'l', }),
          theme.type(0, { from: 'l', until: 'xl', }),
        ],
      })}
      >
        {tldr && tldr.map((val, i) => (
          <Paragraph
            // eslint-disable-next-line react/no-array-index-key
            key={`tldr${i}`}
            {...val}
            miscStyles={{
              lineHeight: '1.7em !important',
            }}
            useDefaultType={false}
          />
        ))}

      </div>
      <div className={css({
        gridArea: 'tldr',
        display: 'flex',
        alignItems: 'flex-end',
        backgroundImage: 'linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 80%)',
      })}
      >
        <span className={css({
          textDecoration: 'underline',
          paddingTop: '16rem',
          paddingBottom: '3rem',
        })}
        >
          {theme.broadcastListI18.redirectArticle}
        </span>
      </div>

    </>
  );
}
