// @flow
import React from 'react';
import { useFela, } from 'react-fela';
import { useInView, } from 'react-intersection-observer';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';

import Teaser from '../../../Teaser/NewTeaser';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import Image from '../../../Image/Image';
import getImageAssets from '../../../../utils/getImageAssets';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import H from '../../../AutoLevels/H';

type TeaserPropTypes = {
  item: TeaserDataType,
  isLazyloadImages: boolean,
  theme: Object,
  biAction: ?() => void,
  biImpression: () => void,
};

Broadcast1Teaser.defaultProps = {
  isLazyloadImages: true,
  theme: null,
  biAction: null,
};

const avatarSize = [
  { until: 's', value: '15rem', },
  { from: 's', value: '17rem', },
];

const areasTemplate = [
  {
    until: 's',
    value: `
  ". . "
   "media ."
   "media sectionName"
   "media content" " . . "`, },

  {
    from: 's',
    value: `
  " . . . . . "
  " . . media . . "
  " . . . . . "
  "  . sectionName sectionName sectionName . "
  "  . content content content . "
  " . . . . . "`, },
];

const colTemplate = [
  { until: 's', value: '17rem auto', },
  { from: 's', value: '5rem auto auto auto 5rem', },
];

const rowTemplate = [
  { until: 's', value: '2rem 1rem auto 1fr 1rem', },
  { from: 's', until: 'xl', value: '4rem 18rem 2rem minmax(4rem, auto) minmax(17rem, auto) 4rem', },
  { from: 'xl', value: '3rem 17rem 2rem 4rem minmax(12rem, auto) 3rem', },
];

const teaserMediaMiscStyles = {
  borderRadius: '50%',
  height: avatarSize,
  overflow: 'hidden',
  width: avatarSize,
  alignSelf: [ { until: 'xl', value: 'center', }, ],
  justifySelf: [ { from: 's', value: 'center', }, ],
};


export default function Broadcast1Teaser({
  item,
  isLazyloadImages,
  theme,
  biAction,
  biImpression,
}: TeaserPropTypes) {
  const { css, } = useFela();

  const [ ref, inView, ] = useInView({ threshold: 0.3, triggerOnce: true, });
  const [ isImpressionSent, setIsImpressionSent, ] = React.useState(false);

  React.useEffect(() => {
    if (inView && item && !isImpressionSent && biImpression) {
      biImpression();
      setIsImpressionSent(true);
    }
  }, [ biImpression, inView, isImpressionSent, item, ]);

  if (!item) return null;

  const { contentId, image, sectionName, title, exclusive, } = item;

  return (
    <Teaser
      fwRef={ref}
      key={contentId}
      areasTemplate={areasTemplate}
      colTemplate={colTemplate}
      rowTemplate={rowTemplate}
      miscStyles={{
        borderBottom: [ { until: 's', value: [ '1px', 1, 'solid', theme.color('neutral', '-4'), ], }, ],
        justifyItems: [ { from: 's', value: 'center', }, ],
      }}
    >
      <TeaserMedia
        data={item}
        miscStyles={teaserMediaMiscStyles}
        onClick={biAction}
      >
        {image
          ? (
            <Image
              image={image}
              imgOptions={getImageAssets({
                bps: theme.bps,
                aspect: 'square',
                sizes: [ { size: '119px', }, ],
                widths: [ 180, 119, ],
              })}
              lazyLoad={isLazyloadImages}
            />
          ) : null }
      </TeaserMedia>
      <H className={css({
        gridArea: 'sectionName',
        extend: [
          theme.type(1, { untilBp: 's', }),
          theme.type(2, { fromBp: 's', untilBp: 'xl', }),
          theme.type(1, { fromBp: 'xl', }),
          theme.mq({ from: 's', }, { textAlign: 'center', display: 'flex', alignItems: 'center', },),
        ],
      })}
      >
        {exclusive || sectionName}
      </H>
      {title ? (
        <TeaserHeader
          {...item}
          offset={1}
          onClick={biAction}
          typeScale={[
            { until: 's', value: -1, },
            { from: 'xl', value: -1, },
          ]}
          wrapperMiscStyles={{
            marginTop: [ { until: 's', value: '0.5rem', }, { from: 's', value: '1rem', }, ],
            textAlign: [ { until: 's', value: 'start', }, { from: 's', value: 'center', }, ],
          }}
          miscStyles={{
            fontWeight: 400,
          }}
          isGridItem
          showKicker={false}
        />
      ) : null}
    </Teaser>
  );
}
