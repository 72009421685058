// @flow
import * as React from 'react';
import { BROADCAST1_LIST_QUERY, } from '@haaretz/graphql';
import Broadcast1View from './Broadcast1View';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';

type Broadcast1WrapperProps = {
  updateListDuplication: Function,
  listData: ListDataType,
}

export default function Broadcast1Wrapper(props: Broadcast1WrapperProps): React.Node {
  return (
    <ListWrapper {...props} query={BROADCAST1_LIST_QUERY} view="Broadcast1">
      {dataProps => <Broadcast1View {...dataProps} />}
    </ListWrapper>
  );
}
