// @flow
import React from 'react';
import { useFela, } from 'react-fela';
import { parseStyleProps, } from '@haaretz/htz-css-tools';
import type { StyleProps, } from '@haaretz/htz-css-tools';

import LayoutContainer from '../../../PageLayout/LayoutContainer';
import H from '../../../AutoLevels/H';
import UserDispenser from '../../../User/UserDispenser';
import IconStar from '../../../IconStar/IconStar';
import IconAvatar from '../../../Icon/icons/IconAvatar';

type Props = {
  title: ?string,
  miscStyles: ?StyleProps,
};

Broadcast1ListViewHeader.defaultProps = {
  miscStyles: null,
  title: null,
};


export function Broadcast1ListViewHeader({ title, miscStyles, }: Props) {
  const { css, theme, } = useFela();
  return (
    <LayoutContainer
      namedBgc="transparent"
      miscStyles={{
        marginTop: [ { until: 'm', value: '4rem', },
          { from: 'm', until: 'l', value: '6.5rem', },
          { from: 'l', until: 'xl', value: '9rem', },
          { from: 'xl', value: '7.5rem', }, ],
        color: theme.color('neutral', '-1'),
        textAlign: 'center',
        ...(miscStyles || {}),
      }}
    >
      <H
        className={css({
          extend: [
            theme.mq({ until: 's', }, { display: 'none', }),
            theme.type(8, { fromBp: 's', }),
          ],
        })}
      >
        {title}
      </H>
      <UserDispenser
        render={({ user, }) => user && user.firstName && (
        <H className={css({
          fontWeight: 500,
          marginTop: '1rem',
          extend: [
            theme.type(4, { untilBp: 'l', }),
            theme.type(5, { fromBp: 'l', }),
            theme.mq({ until: 's', }, { display: 'flex', flexDirection: 'column', },),
          ],
        })}
        >
          <span>{theme.broadcastListI18.greetUser.first(user.firstName || '')}</span>
          {' '}
          <span className={css({
            extend: [ theme.mq({ until: 's', }, { fontWeight: 700, }), ],
          })}
          >
            {theme.broadcastListI18.greetUser.second}
          </span>
        </H>
        )}
      />
    </LayoutContainer>
  );
}


type MobileProps = {
  title: string,
  description: string,
  miscStyles: ?StyleProps,
  iconName: string,
};

BroadcastMobileListViewHeader.defaultProps = {
  title: '',
  description: '',
  miscStyles: null,
  iconName: null,
};

export function BroadcastMobileListViewHeader({
  title,
  iconName,
  description,
  miscStyles, }: MobileProps) {
  const { css, theme, } = useFela();
  const Icon = iconName && getIconComponent(iconName);

  return (
    <header
      className={css({
        extend: [
          theme.mq({ from: 's', }, { display: 'none', }),
          theme.type(2, { untilBp: 's', }),
          ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
        ],
      })}
    >
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: theme.color('neutral', '-10'),
          position: 'relative',
          zIndex: 1,
        })}
      >
        <div
          className={css({
            display: 'flex',
            height: '6rem',
            transform: 'translate(0, -40%)',
          })}
        >
          {Icon
          && (
          <div className={css({
            display: 'grid',
            placeItems: 'center',
            width: '6rem',
            backgroundColor: theme.color('neutral', '-1'),
          })}
          >
            {Icon}
          </div>
          )}
          <H className={css({
            display: 'grid',
            placeItems: 'center',
            paddingStart: '1rem',
            paddingEnd: '2rem',
            backgroundColor: theme.color('primary', '-3'),
          })}
          >
            {title}
          </H>
        </div>
        {description
          ? (
            <p
              className={css({
                fontWeight: 400,
                padding: '0rem 2rem 2rem',
                extend: [
                  theme.type(0),
                ],
              })}
            >
              {description}
            </p>
          ) : null}
      </div>
    </header>
  );
}


export function getIconComponent(iconName: string) {
  switch (iconName) {
    case 'iconStar':
      return (
        <IconStar
          leftColor={[ 'primary', '-3', ]}
          rightColor={[ 'primary', '-3', ]}
        />
      );

    case 'iconAvatar':
      return <IconAvatar size={4.5} color={[ 'primary', '-3', ]} />;

    default:
      return null;
  }
}
