// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import type { Node, } from 'react';

import HyperChickenItem from './HyperChickenItem';
import LayoutContainer from '../../../PageLayout/LayoutContainer';
import LayoutRow from '../../../PageLayout/LayoutRow';
import BoxyMedia from '../Boxy/BoxyMedia';
import setBiAction from '../../../../utils/setBiAction';

import type { ImageDataType, } from '../../../../flowTypes/ImageDataType';
import type { HTMLEmbedDataType, } from '../../../../flowTypes/HTMLEmbedDataType';
import type { GalleryDataType, } from '../../../../flowTypes/GalleryDataType';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import type { ListBiActionType, } from '../../../../flowTypes/ListBiActionType';

type MediaType = ImageDataType | HTMLEmbedDataType | GalleryDataType;

type Props = {
  list: ListDataType,
  listId: string,
  isLazyloadImages: boolean,
  gaAction: ?() => void,
  biAction: ?ListBiActionType,
  isColorInverse?: boolean,
};

HyperChicken.defaultProps = {
  isColorInverse: true,
};

export default function HyperChicken({
  list,
  isLazyloadImages,
  biAction,
  gaAction,
  isColorInverse,
}: Props): Node {
  const { theme, } = useFela();
  const item: TeaserDataType = list.items[0];
  const extraLinks = list.extraLinks;
  const media: ?MediaType = item ? item.media : null;
  return item && media && [ 'image', 'embed', ].includes(media.kind) ? (
    <LayoutRow
      bgc={isColorInverse ? 'transparent' : theme.color('neutral', '-2')}
      miscStyles={{
        marginTop: [ { until: 's', value: 6, }, { from: 's', value: 8, }, ],
      }}
      attrs={{
        'data-test': 'hyperChicken',
      }}
    >
      <HyperChickenItem
        extraLinks={extraLinks}
        item={item}
        wrapper={LayoutContainer}
        onClick={setBiAction(0, item, biAction)}
      >
        <BoxyMedia media={media} isLazyloadImages={isLazyloadImages} />
      </HyperChickenItem>
    </LayoutRow>
  ) : null;
}
