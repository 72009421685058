// @flow
import * as React from 'react';

import { BILINGUAL_LIST_QUERY, } from '@haaretz/graphql';
import Bilingual1View from './Bilingual1View.js';
import Bilingual2View from './Bilingual2View.js';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';

type Props = {
  updateListDuplication: Function,
  variables: {
    history: ?(string[]),
  },
  listData: ListDataType,
};

export default function Bilingual(props: Props): React.Node {
  return (
    <ListWrapper {...props} query={BILINGUAL_LIST_QUERY} view="Bilingual">
      {dataProps => {
        if (dataProps.list && dataProps.list.view === 'Debate1') {
          return <Bilingual1View {...dataProps} />;
        }
        if (dataProps.list && dataProps.list.view === 'Debate2') {
          return <Bilingual2View {...dataProps} history={props.variables.history} />;
        }
        return null;
      }}
    </ListWrapper>
  );
}
