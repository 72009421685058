// @flow
import React, { Fragment, } from 'react';
import { useFela, } from 'react-fela';

import type { ContinuableListViewProps, } from '../../../../flowTypes/ListViewProps';
import ListView from '../../../ListView/NewListView';
import Broadcast1Teaser from './Broadcast1Teaser';
import { Broadcast1ListViewHeader, BroadcastMobileListViewHeader, } from './Broadcast1ListViewHeader';
import setBiAction from '../../../../utils/setBiAction';
import setBiImpression from '../../../../utils/setBiImpression';

Broadcast1View.defaultProps = {
  sectionId: null,
  isLazyloadImages: true,
};


export default function Broadcast1View({
  isLazyloadImages,
  list,
  history,
  listId,
  biAction,
  biImpression,
}: ContinuableListViewProps) {
  const { theme, css, } = useFela();
  const { items, title, } = list;

  if (!items) return null;

  const colTemplate = [
    { until: 's', value: 'repeat(1, auto)', },
    { from: 's', until: 'l', value: 'repeat(2, 1fr)', },
    { from: 'l', value: 'repeat(4, 1fr)', },
  ];

  const rowTemplate = [
    { until: 's', value: 'repeat(4, auto)', },
    { from: 's', until: 'm', value: 'repeat(2, auto)', },
    { from: 'l', until: 'xl', value: 'repeat(1, auto)', },
    { from: 'xl', value: 'repeat(1, auto)', },
  ];

  const rowGap = [
    { until: 's', value: '0rem', },
    { from: 's', value: '4rem', },
  ];

  const colGap = [
    { until: 's', value: '0', }, { from: 's', value: '4rem', },
  ];

  return (
    <Fragment>
      <div className={css({
        extend: [
          theme.mq({ until: 's', }, { padding: '2rem', }),
        ],
      })}
      >
        <Broadcast1ListViewHeader
          title={title}
        />
        <ListView
          colTemplate={colTemplate}
          rowTemplate={rowTemplate}
          rowGap={rowGap}
          colGap={colGap}
          gridGap={null}
          innerBackgroundColor={[ { until: 's', value: [ 'neutral', '-10', ], }, ]}
        >
          <BroadcastMobileListViewHeader
            title={title || ''}
            miscStyles={{
              transform: 'translate(2rem, 0)',
            }}
            iconName="iconStar"
          />
          {items.map((item, i) => (
            <Broadcast1Teaser
              key={item.contentId}
              item={item}
              isLazyloadImages={isLazyloadImages}
              theme={theme}
              biAction={setBiAction(i, item, biAction)}
              biImpression={setBiImpression(i, item, biImpression)}
            />
          ))}
        </ListView>
      </div>
    </Fragment>
  );
}
