// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { type ListBiActionType, } from '../../../../flowTypes/ListBiActionType';
import { type ListDataType, } from '../../../../flowTypes/ListDataType';

import Section from '../../../AutoLevels/Section';
import ListView from '../../../ListView/NewListView';
import setBiAction from '../../../../utils/setBiAction';
import AharoniTeaser from './AharoniTeaser';
import setBiImpression from '../../../../utils/setBiImpression';
import ListViewSearchResultsHeader from '../../../Algolia/AlgoliaList/ListViewSearchResultsHeader';

type Props = {
  query: ?string,
  totalHits: number,
  list: ListDataType,
  isLazyloadImages: boolean,
  isHideHeader: boolean,
  gaAction: () => void,
  biAction: ?ListBiActionType,
  biImpression: ?ListBiImpressionType,
};

AharoniView.defaultProps = {
  biAction: null,
  gaAction: null,
  query: null,
  isLazyloadImages: true,
  isHideHeader: false,
  biImpression: null,
};

export default function AharoniView({
  list,
  query,
  totalHits,
  isLazyloadImages,
  isHideHeader,
  biAction,
  gaAction,
  biImpression,
}: Props) {
  const { items, } = list;
  const { css, theme, } = useFela();

  if (!(items && items.length)) return null;

  const contentClasses = css({
    gridArea: 'content',
    display: 'grid',
    gridGap: '2rem',
    gridTemplateColumns: '1fr 1fr',
    ...theme.mq({ from: 's', until: 'l', }, { gridTemplateColumns: '1fr', }),
    ...theme.mq({ from: 'l', }, { gridTemplateColumns: '1fr 1fr', }),
    extend: [ theme.mq({ from: 'l', }, { gridGap: '4rem', }), ],
  });

  return (
    <ListView
      areasTemplate="'he' 'content'"
      gridGap={[ { until: 'l', value: '1rem 2rem', }, { from: 'l', value: '2rem 4rem', }, ]}
      marginTop={[ { until: 'l', value: '2rem', }, { from: 'l', value: '4rem', }, ]}
      attrs={{
        'data-test': 'aharoni',
      }}
    >
      {isHideHeader ? null : <ListViewSearchResultsHeader queryString={query} resultsCount={totalHits} />}
      <Section tagName="div" className={contentClasses}>
        {items.map((item, i) => (item ? (
          <AharoniTeaser
            key={item.contentId}
            data={item}
            isLazyloadImages={isLazyloadImages}
            biAction={setBiAction(i, item, biAction)}
            biImpression={setBiImpression(i, item, biImpression)}
            theme={theme}
          />
        ) : null)
        )}
      </Section>
    </ListView>
  );
}
