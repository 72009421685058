// @flow
import * as React from 'react';
import { CRUSHINATOR_LIST_QUERY, } from '@haaretz/graphql';
import CrushinatorView from './CrushinatorView.js';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';

type CrushinatorWrapperProps = {
  updateListDuplication: Function,
  variables: {
    offset: number,
  },
  listData: ListDataType,
  isPersonal: boolean,
}

export default function CrushinatorWrapper(props: CrushinatorWrapperProps) {
  return (
    <ListWrapper {...props} query={CRUSHINATOR_LIST_QUERY} view="Crushinator">
      {dataProps => <CrushinatorView {...dataProps} />}
    </ListWrapper>
  );
}
