// @flow
import { useFela, } from 'react-fela';
import React, { type Node, } from 'react';

import type { ListViewProps, } from '../../../../flowTypes/ListViewProps';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';
import Image from '../../../Image/Image';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import setBiAction from '../../../../utils/setBiAction';
import ListView from '../../../ListView/NewListView';
import H from '../../../AutoLevels/H';
import Section from '../../../AutoLevels/Section';
import TeaserResponsiveText from '../../../TeaserResponsiveText/TeaserResponsiveText';
// import { BlockLinkInner, } from '../../../BlockLink/BlockLink';
import AboveBlockLink from '../../../BlockLink/AboveBlockLink';
import HtzLink from '../../../HtzLink/HtzLink';
import getImageAssets from '../../../../utils/getImageAssets';

const getAreasTemplate = listLength => (listLength === 6
  ? [
    {
      until: 's',
      value: `
  "t1"
  "t2"
  "t3"
  "t4"
  "t5"
  "t6"
      `,
    },
    {
      from: 's',
      until: 'l',
      value: `
      "t1 t2"
      "t3 t4"
      "t5 t6"
      `,
    },
    {
      from: 'l',
      value: `
      "t1 t2 t3"
      "t4 t5 t6"
      `,
    },
  ]
  : [
    {
      until: 's',
      value: `
  "t1"
  "t2"
  "t3"
      `,
    },
    {
      from: 's',
      until: 'l',
      value: `
      "t1 t2"
      `,
    },
    {
      from: 'l',
      value: `
      "t1 t2 t3"
      `,
    },
  ]);

CrushinatorView.defaultProps = {
  isLazyloadImages: true,
  biAction: null,
  gaAction: null,
};

export default function CrushinatorView({
  list,
  isLazyloadImages,
  gaAction,
  biAction,
}: ListViewProps): Node {
  const { items, } = list || {};
  if (!(items && items.length)) return null;

  const listLength = items.length >= 6 ? 6 : 3;

  const slicedItems = items.slice(0, listLength);

  function itemRenderer(data, i) {
    if (data) {
      return (
        <CrushinatorItem
          key={data.contentId}
          item={data}
          biAction={setBiAction(i, data, biAction)}
          isLazyloadImages={isLazyloadImages}
          gridArea={`t${i + 1}`}
          hideOnMediumBreak={listLength === 3 && i === 2}
        />
      );
    }
    return null;
  }

  return (
    <React.Fragment>
      <ListView
        // outerBackgroundColor={[ 'neutral', '-3', ]}
        // innerBackgroundColor={[
        //   { until: 's', value: [ 'layout', 'rowBg', ], },
        //   { from: 's', value: [ 'neutral', '-2', ], },
        // ]}
        areasTemplate={getAreasTemplate(listLength)}
        // padding={[ { until: 's', value: '0rem 2rem', }, { from: 's', value: '4rem 4rem', }, ]}
        colTemplate={[
          { until: 's', value: '1fr', },
          { from: 's', until: 'l', value: '1fr 1fr', },
          { from: 'l', value: '1fr 1fr 1fr', },
        ]}
        attrs={{
          'data-test': 'crushinator',
        }}
      >
        {slicedItems.map(itemRenderer)}
      </ListView>
    </React.Fragment>
  );
}

function CrushinatorItem({
  item,
  biAction,
  isLazyloadImages,
  gridArea,
  hideOnMediumBreak,
}: {
  item: TeaserDataType,
  biAction: ?() => void,
  isLazyloadImages: boolean,
  gridArea: string,
  hideOnMediumBreak: boolean,
}): Node {
  const { css, theme, } = useFela();

  const image = item?.mobileImage || item?.image;

  return (
    <Teaser
      areasTemplate='"media" "content"'
      // rowTemplate="auto 1fr auto"
      rowGap="0rem"
      miscStyles={{
        position: 'relative',
        color: 'white',
        ...(hideOnMediumBreak ? { display: [ { from: 's', until: 'l', value: 'none', }, ], } : {}),
      }}
    >
      <TeaserMedia data={item} onClick={biAction}>
        <Image
          image={image}
          imgOptions={getImageAssets({
            bps: theme.bps,
            aspect: 'square',
            sizes: [
              { from: 'xl', size: '385px', },
              { from: 'l', size: '310', },
              { from: 'm', size: '350px', },
              { from: 's', size: '264px', },
              { size: 'calc(100vw -4rem)', },
            ],
            widths: [ 1080, 720, 385, 310, 270, ],
          })}
          lazyLoad={isLazyloadImages}
        />
      </TeaserMedia>
      <Section isFragment>
        <AboveBlockLink>
          {({ className, }) => item.title && (
          // We use an offset here, because the title should be the same level
          // as a header inside a section, no the same as a section's title
          <H
            className={css({
              position: 'absolute',
              bottom: '4rem',
              textAlign: 'center',
              paddingInlineStart: '4rem',
              paddingInlineEnd: '4rem',
              zIndex: theme.getZIndex('above') + 1,
              extend: [ theme.type(1), ],
            })}
          >
            <HtzLink href={item.path} className={className}>
              <TeaserResponsiveText text={item.title} mobileText={item.titleMobile} kind="title" />
            </HtzLink>
          </H>
          )
          }
        </AboveBlockLink>
      </Section>
      <span
        className={css({
          display: 'block',
          height: '22rem',
          position: 'absolute',
          width: '100%',
          bottom: '0',
          margin: 'auto',
          zIndex: theme.getZIndex('above'),
          backgroundImage:
            'linear-gradient(0deg,#000,#000 0%,hsla(0,0%,0%,.8) 60%,hsla(0,0%,0%,0))',
          extend: [ theme.mq({ until: 's', }, { bottom: 0, }), ],
        })}
      />
    </Teaser>
  );
}
