// @flow
import React from 'react';
import { useFela, } from 'react-fela';
import { useInView, } from 'react-intersection-observer';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import Image from '../../../Image/Image';
import getImageAssets from '../../../../utils/getImageAssets';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import H from '../../../AutoLevels/H';


type TeaserPropTypes = {
  biAction: ?() => void,
  item: TeaserDataType,
  isLazyloadImages: boolean,
  theme: Object,
  biImpression: () => void,
};

Broadcast3Teaser.defaultProps = {
  isLazyloadImages: true,
  theme: null,
  biAction: null,
};


export default function Broadcast3Teaser({
  item,
  isLazyloadImages,
  biAction,
  biImpression,
  theme, }: TeaserPropTypes) {
  const { css, } = useFela();
  const [ ref, inView, ] = useInView({ threshold: 0.3, triggerOnce: true, });
  const [ isImpressionSent, setIsImpressionSent, ] = React.useState(false);

  React.useEffect(() => {
    if (inView && item && !isImpressionSent && biImpression) {
      biImpression();
      setIsImpressionSent(true);
    }
  }, [ biImpression, inView, isImpressionSent, item, ]);

  if (!item) return null;

  const avatarSize = [
    { from: 'l', until: 'xl', value: '14rem', },
    { from: 'xl', value: '13rem', },
  ];


  const areasTemplate = `
  " . . . . . . "
  " . media . sectionName sectionName . "
  " . media . content content . "
  " . media . . . . "
  " . . . . . . "
`;
  const colTemplate = '2rem auto 2rem auto auto 2rem';

  const rowTemplate = '3rem auto auto auto auto 3rem';


  const teaserMediaMiscStyles = {
    borderRadius: '50%',
    height: avatarSize,
    overflow: 'hidden',
    width: avatarSize,
  };

  const image = item?.mobileImage || item?.image;

  return (
    <Teaser
      fwRef={ref}
      areasTemplate={areasTemplate}
      colTemplate={colTemplate}
      rowTemplate={rowTemplate}
      miscStyles={{
        marginTop: '2rem',
      }}
    >
      <TeaserMedia
        data={item}
        onClick={biAction}
        miscStyles={teaserMediaMiscStyles}
      >
        {image
          ? (
            <Image
              image={image}
              imgOptions={getImageAssets({
                bps: theme.bps,
                aspect: 'square',
                sizes: [ { size: '119px', }, ],
                widths: [ 180, 119, ],
              })}
              lazyLoad={isLazyloadImages}
            />
          ) : null }
      </TeaserMedia>
      <div className={css({
        gridArea: 'sectionName',
      })}
      >
        <H>{item.sectionName}</H>
      </div>

      {item.title ? (
        <TeaserHeader
          {...item}
          onClick={biAction}
          typeScale={-1}
          miscStyles={{
            fontWeight: 400,
          }}
          isGridItem
        />
      ) : null}
    </Teaser>
  );
}
