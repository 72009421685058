// @flow
import * as React from 'react';
// Conrad and Wong use the exact same query
import { BROADCAST2_LIST_QUERY, } from '@haaretz/graphql';
import Broadcast2View from './Broadcast2View';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';


type Broadcast2WrapperProps = {
  updateListDuplication: Function,
  listData: ListDataType,
}

export default function Broadcast2Wrapper(props: Broadcast2WrapperProps): React.Node {
  return (
    <ListWrapper {...props} query={BROADCAST2_LIST_QUERY} view="Broadcast2">
      {dataProps => <Broadcast2View {...dataProps} />}
    </ListWrapper>
  );
}
