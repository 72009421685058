// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import type { Node, } from 'react';
import HtzLink from '../../../HtzLink/HtzLink';

import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';
import type { ListExtraLinkType, } from '../../../../flowTypes/ListExtraLinkType';
import LayoutContainer from '../../../PageLayout/LayoutContainer';
import TeaserResponsiveText from '../../../TeaserResponsiveText/TeaserResponsiveText';
import H from '../../../AutoLevels/H';

type FontWeightType = 600 | 400 | 'bold' | 'normal';

type Props = {
  item: TeaserDataType,
  children: Node,
  offset?: number,
  fontWeight?: FontWeightType,
  onClick?: ?Function,
  gridArea?: ?string,
  isColorInverse?: boolean,
  extraLinks?: ListExtraLinkType[],
};

HyperChickenItem.defaultProps = {
  offset: 1,
  fontWeight: 600,
  onClick: null,
  gridArea: null,
  isColorInverse: true,
  // isColorInverse: false,
  extraLinks: null,
};

export default function HyperChickenItem({
  item,
  children,
  offset,
  fontWeight,
  onClick,
  gridArea,
  isColorInverse,
  extraLinks,
}: Props): Node {
  const { css, theme, } = useFela();
  return (
    <div
      className={css({
        position: 'relative',
        gridArea: gridArea || null,
      })}
    >
      {children}
      <div
        className={css({
          position: 'absolute',
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          color: 'white',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        })}
      >
        <LayoutContainer
          bgc="transparent"
          miscStyles={{
            paddingInlineStart: [ { until: 's', value: '2rem', }, { from: 's', value: '4rem', }, ],
            paddingInlineEnd: [ { until: 's', value: '2rem', }, { from: 's', value: '4rem', }, ],
          }}
        >
          <H
            className={css({
              marginTop: '4rem',
              textAlign: 'center',
              extend: [
                theme.type(6, { fromBp: 'l', }),
                theme.type(4, { fromBp: 's', untilBp: 'l', }),
                theme.type(2, { untilBp: 's', }),
              ],
            })}
          >
            <TeaserResponsiveText text={item.title} mobileText={item.titleMobile} />
          </H>
          <div
            className={css({
              justifyContent: 'space-between',
              alignItems: 'center',
              display: 'flex',
              marginTop: '12rem',
              extend: [
                theme.mq(
                  { until: 's', },
                  {
                    marginTop: '3rem',
                    justifyItems: 'center',
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gridRowGap: '4rem',
                    maxWidth: '51rem',
                    marginInlineStart: 'auto',
                    marginInlineEnd: 'auto',
                  }
                ),
                theme.mq(
                  { from: 's', until: 'm', },
                  {
                    marginTop: '4rem',
                  }
                ),
                theme.mq(
                  { from: 'm', until: 'l', },
                  {
                    marginTop: '6rem',
                  }
                ),
                theme.mq(
                  { from: 'l', until: 'xl', },
                  {
                    marginTop: '8rem',
                  }
                ),
              ],
            })}
          >
            {extraLinks && extraLinks.map(link => <HyperChickenLink link={link} />)}
          </div>
        </LayoutContainer>
      </div>
    </div>
  );
}

function HyperChickenLink({ link, }: { link: ListExtraLinkType, }) {
  const { css, theme, } = useFela();
  const [ isHovered, setIsHovered, ] = React.useState(false);

  const tooltip = link.linkExtraData?.tooltip;

  return (
    <HtzLink
      href={link.url}
      attrs={{
        onMouseEnter: () => {
          setIsHovered(true);
        },
        onMouseLeave: () => {
          setIsHovered(false);
        },
      }}
      className={css({
        backgroundColor: 'transparent',
        border: 'solid 4px rgba(255,255,255,0.7)',
        borderRadius: '50%',
        padding: '2rem',
        width: '40rem',
        height: '40rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontWeight: 700,
        ':hover': {
          cursor: 'pointer',
          color: 'white',
          borderColor: 'white',
        },
        ':visited': {
          color: 'white',
        },
        extend: [
          theme.type(4, { fromBp: 'l', }),
          theme.type(2, { fromBp: 'm', untilBp: 'l', }),
          theme.type(1, { untilBp: 'm', }),
          theme.mq({ from: 'l', until: 'xl', }, { height: '35rem', width: '35rem', }),
          theme.mq({ from: 'm', until: 'l', }, { height: '28rem', width: '28rem', }),
          theme.mq({ until: 'l', }, { borderWidth: '3px', }),
          theme.mq({ from: 's', until: 'm', }, { height: '21rem', width: '21rem', }),
          theme.mq({ until: 's', }, { height: '21rem', width: '21rem', }),
        ],
      })}
    >
      {isHovered ? tooltip : link.title}
    </HtzLink>
  );
}
