// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';

import { BILINGUAL_LIST_QUERY, } from '@haaretz/graphql';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';
import type { ContinuableListViewProps, } from '../../../../flowTypes/ListViewProps';

import ListView from '../../../ListView/NewListView';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';
import Picture from '../../../Image/Picture';
import Section from '../../../AutoLevels/Section';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserFooter from '../../../TeaserFooter/TeaserFooter';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import getPictureAssets from '../../../../utils/getPictureAssets';
import setBiAction from '../../../../utils/setBiAction';
import Paginator from '../../Paginator';

Bilingual2.defaultProps = {
  biAction: null,
  gaAction: null,
  isLazyloadImages: true,
};

const teaserDefaultProps = { isLazyloadImages: true, biAction: null, };
const teaserGridAreaNames = [ 'span 2', 'span 1', 'span 1', 'span 2', ];

const teasers = [
  BilingualMediaTeaser,
  BilingualTextualTeaser,
  BilingualTextualTeaser,
  BilingualMediaTeaser,
];

const areasTemplate = [
  {
    until: 'l',
    value: `
    "he"
    "t1"
    "textual1"
    "t2"
    "textual2"
    `,
  },
  {
    from: 'l',
    value: `
    "he he he"
    "t1 t1 textual1"
    "textual2 t2 t2"
    `,
  },
];

export default function Bilingual2({
  list,
  isLazyloadImages,
  listId,
  history,
  biAction,
  gaAction,
}: ContinuableListViewProps): React.Node {
  const { theme, } = useFela();
  const { items, extraLinks, ...restOfList } = list || {};

  if (!(items && items.length)) return null;

  function itemRenderer(data, i) {
    if (data && teasers[i % 4]) {
      return teasers[i % 4]({
        data,
        gridColumnEnd: teaserGridAreaNames[i % 4],
        biAction: setBiAction(i, data, biAction),
        isLazyloadImages,
        theme,
      });
    }
    return null;
  }


  const itemsToDisplay = items.reduce((arr, item, index) => {
    if ((index % 2) || (!items[index + 1])) return arr;
    arr.push({ arabicTeaser: item, hebrewTeaser: items[index + 1], });
    return arr;
  }, []);

  return (
    <ListView
      areasTemplate={areasTemplate}
      colTemplate={[ { until: 'l', value: '1fr', }, { from: 'l', value: '5fr 2fr 5fr', }, ]}
      rowTemplate={[ { from: 's', until: 'l', value: 'auto repeat(2, auto minmax(17rem, auto))', }, ]}
      colGap={[ { until: 's', value: '2rem', }, { from: 's', value: '4rem', }, ]}
      gridGap={null}
      rowGap={[ { until: 's', value: '1rem', }, { from: 's', value: '3rem', }, ]}
      Paginator={() => (
        <Paginator
          initialPage={1}
          listId={listId}
          history={history}
          listQuery={BILINGUAL_LIST_QUERY}
          colTemplate={[ { until: 'l', value: '1fr', }, { from: 'l', value: '5fr 2fr 5fr', }, ]}
          rowTemplate={[
            { from: 's', until: 'l', value: 'auto repeat(2, auto minmax(17rem, auto))', },
          ]}
          loadButtonGridColumn={[ { until: 'l', value: '1', }, { from: 'l', value: '1 / 4', }, ]}
          gridGap={[ { until: 's', value: '2rem', }, { from: 's', value: '4rem', }, ]}
          colGap={[ { until: 's', value: '2rem', }, { from: 's', value: '4rem', }, ]}
          rowGap={[ { until: 's', value: '1rem', }, { from: 's', value: '3rem', }, ]}
          namedBgc="transparent"
          padding={[ { until: 's', value: '0 2rem', }, { from: 's', value: '0 4rem', }, ]}
        >
          {itemsToDisplay => <Section isFragment>{itemsToDisplay.map(itemRenderer)}</Section>}
        </Paginator>
      )}
    >
      {restOfList.title || restOfList.commercialLinks?.length || extraLinks?.length ? (
        <ListViewHeader
          {...restOfList}
          extraLinks={null}
          biAction={biAction}
          isHorizontal
          isSticky
        />
      ) : null}
      <Section isFragment>{itemsToDisplay.slice(0, 4).map(itemRenderer)}</Section>
    </ListView>
  );
}

// /////////////////////////////////////////////////////////////////////
//                              TEASERS                               //
// /////////////////////////////////////////////////////////////////////

type TeaserProps = {
  biAction: ?() => void,
  data: TeaserDataType,
  isLazyloadImages?: boolean,
  // gridArea: string,
  gridColumnEnd: string,
  theme: Object,
};

const MainTeaserAreaTempM = `
"media .        .   .       ."
"media titleHeb sep titleAr ."
"media footer footer footer ."
`;

const MainTeaserAreaTempS = `
"media media media"
". titleHeb ."
". titleAr ."
". footer ."
`;

const mediaObjTeaserAreas = `
  "media    .   .   . ."
  "media titleHeb sep titleAr ."
  "media footer footer footer ."
  "media .      .      .      ."
`;

const TeaserAreasNotranslatedArticles = `
  ".  . . .media"
  ". titleHeb titleHeb titleHeb media"
  ". footer footer footer  media"
  ". .  . .media"
`;

BilingualMediaTeaser.defaultProps = teaserDefaultProps;
function BilingualMediaTeaser({
  data,
  // gridArea,
  gridColumnEnd,
  isLazyloadImages,
  biAction,
  theme,
}: TeaserProps): React.Node {
  const { css, } = useFela();
  const { arabicTeaser, hebrewTeaser, } = data;
  if (!(arabicTeaser && hebrewTeaser)) { return null; }
  return (
    <Teaser
      data={arabicTeaser}
      key={arabicTeaser.contentId}
      areasTemplate={[
        { until: 's', value: MainTeaserAreaTempS, },
        { from: 's', until: 'l', value: MainTeaserAreaTempM, },
        {
          from: 'l',
          value: hebrewTeaser ? mediaObjTeaserAreas : TeaserAreasNotranslatedArticles,
        },
      ]}
      gridGap={[ { from: 's', until: 'l', value: '2rem', }, { from: 's', value: '1rem', }, ]}
      rowGap={[ { until: 's', value: '2rem', }, ]}
      colTemplate={[
        { until: 's', value: '2rem 1fr 2rem', },
        { from: 's', until: 'l', value: '2fr 2fr 1px 2fr 0', },
        { from: 'l', value: '3fr 4fr 1px 4fr 0', },
      ]}
      rowTemplate={[
        { from: 's', until: 'l', value: '0 1fr auto', },
        { from: 'l', value: '0 1fr auto', },
      ]}
      gutter={0}
      onClick={biAction}
      miscStyles={{ gridColumnEnd: [ { from: 'l', value: gridColumnEnd, }, ], }}
    >
      <TeaserMedia data={arabicTeaser} onClick={biAction}>
        <Picture
          lazyLoad={isLazyloadImages}
          {...getPictureAssets({
            bps: theme.bps,
            imgData: arabicTeaser.image,
            defaultImgOptions: {
              sizes: 'calc(100vw - 24px)',
              aspect: 'headline',
              widths: [ 560, 476, 390, 296, ],
            },
            sources: [
              {
                aspect: 'regular',
                from: 's',
                sizes: [
                  // { from: 'xl', size: '181px', },
                  { from: 'l', size: '181px', },
                  { from: 'm', size: '224px', },
                  { from: 's', size: '168px', },
                ],
                widths: [ 224, 181, 168, ],
              },
            ],
          })}
        />
      </TeaserMedia>
      <TeaserHeader
        {...arabicTeaser}
        hasBlockLink={false}
        wrapperMiscStyles={{
          gridArea: 'titleHeb',
          display: 'grid',
        }}
        typeScale={[ { until: 'l', value: 0, }, { from: 'l', value: -1, }, ]}
        onClick={biAction}
      />
      {hebrewTeaser ? (
        <>
          <div
            className={css({
              gridArea: 'sep',
              backgroundColor: theme.color('neutral', '-4'),
              ...theme.mq({ until: 's', }, { display: 'none', }),
            })}
            aria-hidden
          />
          <TeaserHeader
            {...hebrewTeaser}
            hasBlockLink={false}
            wrapperMiscStyles={{
              gridArea: 'titleAr',
              display: 'grid',
            }}
            typeScale={[ { until: 'l', value: 0, }, { from: 'l', value: -1, }, ]}
            onClick={biAction}
          />
        </>
      ) : null}
      <TeaserFooter
        data={arabicTeaser}
        miscStyles={{
          textAlign: [ { from: 's', value: 'center', }, ],
          paddingBottom: [ { until: 's', value: '1rem', }, ],
        }}
        showAuthor
        showRank
        showArabicAuthor
      />
    </Teaser>
  );
}

const TxtObjTeaserAreas = `
  ". .      .      .      ."
  ". titleHeb sep titleAr ."
  ". footer footer footer ."
  ". .      .      .      ."
`;
const TxtstackedTeaserAreas = `
  ".     titleHeb  ."
  ".     titleAr   ."
  ".     footer    ."
`;

BilingualTextualTeaser.defaultProps = teaserDefaultProps;
function BilingualTextualTeaser({
  data,
  // gridArea,
  biAction,
  theme,
  gridColumnEnd,
}: TeaserProps): React.Node {
  const { css, } = useFela();
  const { arabicTeaser, hebrewTeaser, } = data;
  if (!(arabicTeaser && hebrewTeaser)) { return null; }
  return (
    <Teaser
      data={arabicTeaser}
      key={arabicTeaser.contentId}
      // gridArea={gridArea}
      areasTemplate={[
        { until: 's', value: TxtstackedTeaserAreas, },
        { from: 's', value: TxtObjTeaserAreas, },
      ]}
      onClick={biAction}
      rowTemplate={[ { from: 's', value: '0 1fr auto 0', }, ]}
      colTemplate={[
        { until: 's', value: '2rem 1fr 2rem', },
        { from: 's', value: '0 1fr 1px 1fr 0', },
      ]}
      gridGap={[ { from: 's', until: 'l', value: '2rem', }, { from: 's', value: '1rem', }, ]}
      rowGap={[ { until: 's', value: '2rem', }, ]}
      miscStyles={{
        gridColumnEnd: [ { from: 'l', value: gridColumnEnd, }, ],
      }}
    >
      <TeaserHeader
        {...arabicTeaser}
        hasBlockLink={false}
        typeScale={[ { until: 'l', value: 0, }, { from: 'l', value: -1, }, ]}
        onClick={biAction}
        wrapperMiscStyles={{
          gridArea: 'titleHeb',
          paddingTop: [ { until: 's', value: '2rem', }, ],
          display: 'grid',
        }}
      />
      {hebrewTeaser ? (
        <>
          <div
            className={css({
              gridArea: 'sep',
              backgroundColor: theme.color('neutral', '-4'),
              ...theme.mq({ until: 's', }, { display: 'none', }),
            })}
            aria-hidden
          />
          <TeaserHeader
            {...hebrewTeaser}
            hasBlockLink={false}
            wrapperMiscStyles={{
              gridArea: 'titleAr',
              display: 'grid',
            }}
            typeScale={[ { until: 'l', value: 0, }, { from: 'l', value: -1, }, ]}
            onClick={biAction}
          />
        </>
      ) : null}
      <TeaserFooter
        miscStyles={{
          textAlign: [ { from: 's', value: 'center', }, ],
          paddingBottom: [ { until: 's', value: '1rem', }, ],
        }}
        showAuthor
        showArabicAuthor
        showRank
        data={arabicTeaser}
      />
    </Teaser>
  );
}
