// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { type StyleProps, parseStyleProps, } from '@haaretz/htz-css-tools';
import H from '../../AutoLevels/H';

type PropsType = {
  /**
   * The query string used for search
   */
  queryString: ?string,
  /**
   * Number of hits found
   */
  resultsCount: ?number,
  /**
   * A special property applying miscellaneous CSS values that
   * trump all default values. Processed by
   * [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
   */
  miscStyles: ?StyleProps,
  /**
   * A special property applying miscellaneous CSS values that
   * trump all default values of the tile. Processed by
   * [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
   */
  titleMiscStyles: ?StyleProps,
};

function headerStyle({ theme, miscStyles, }) {
  return {
    extend: [ ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []), ],
  };
}

function titleStyle({ theme, titleMiscStyles, }) {
  return {
    color: theme.color('primary'),
    fontWeight: 400,
    ...theme.type(-2, { untilBp: 'l', }),
    ...theme.type(0, { fromBp: 'l', }),
    extend: [ ...(titleMiscStyles ? parseStyleProps(titleMiscStyles, theme.mq, theme.type) : []), ],
  };
}

export default function ListViewSearchResultsHeader({
  queryString,
  resultsCount,
  miscStyles,
  titleMiscStyles,
}: PropsType) {
  const { css, theme, } = useFela({ miscStyles, titleMiscStyles, });
  const titleText = theme.headerSearchI18n.foundResultsText
    .replace(/\{__COUNT__\}/, resultsCount)
    .replace(/\{__QUERY__\}/, queryString);

  const headerClasses = css(headerStyle);
  const titleClasses = css(titleStyle);

  return (
    <header className={headerClasses}>
      <H className={titleClasses}>{titleText}</H>
    </header>
  );
}

ListViewSearchResultsHeader.defaultProps = {
  queryString: null,
  resultsCount: 0,
  miscStyles: null,
  titleMiscStyles: null,
};
