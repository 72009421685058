// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';

import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';
import type { ListViewProps, } from '../../../../flowTypes/ListViewProps';

import Image from '../../../Image/Image';
import ListView from '../../../ListView/NewListView';
import Picture from '../../../Image/Picture';
import Section from '../../../AutoLevels/Section';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserFooter from '../../../TeaserFooter/TeaserFooter';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import getImageAssets from '../../../../utils/getImageAssets';
import getPictureAssets from '../../../../utils/getPictureAssets';
import setBiAction from '../../../../utils/setBiAction';

Bilingual.defaultProps = {
  biAction: null,
  gaAction: null,
  isLazyloadImages: true,
};

const teaserDefaultProps = { isLazyloadImages: true, biAction: null, };
const teaserGridAreaNames = [ 'main', 't2', 't3', 'textual', ];
const teasers = [
  BilingualMainTeaser,
  BilingualTwoUpTeaser,
  BilingualTwoUpTeaser,
  BilingualTextualTeaser,
];

const areasTemplate = [
  {
    until: 'l',
    value: `
    "main"
    "t2"
    "t3"
    "textual"
    `,
  },
  {
    from: 's',
    value: `
    "main t2"
    "main t3"
    "main textual"
    `,
  },
];

export default function Bilingual({
  list,
  isLazyloadImages,
  biAction,
  gaAction,
}: ListViewProps): React.Node {
  const { theme, } = useFela();
  const { items, extraLinks, ...restOfList } = list || {};
  if (!(items && items.length)) return null;

  function itemRenderer(data, i) {
    if (data && teasers[i] && i < 8) {
      return teasers[i]({
        data,
        gridArea: teaserGridAreaNames[i],
        biAction: setBiAction(i, data, biAction),
        isLazyloadImages,
        theme,
      });
    }
    return null;
  }

  const itemsToDisplay = items.reduce((arr, item, index) => {
    if ((index % 2) || (!items[index + 1])) return arr;
    arr.push({ arabicTeaser: item, hebrewTeaser: items[index + 1], });
    return arr;
  }, []);


  return (
    <ListView
      areasTemplate={areasTemplate}
      colTemplate={[ { until: 'l', value: '1fr', }, { from: 'l', value: '1fr 1fr', }, ]}
      gridGap={null}
      rowGap={[ { until: 's', value: '1rem', }, { from: 's', value: '3rem', }, ]}
      colGap={[ { until: 's', value: '2rem', }, { from: 's', value: '4rem', }, ]}
      rowTemplate={[ { from: 'l', value: 'auto auto 1fr', }, ]}
    >
      <Section isFragment>{itemsToDisplay.map(itemRenderer)}</Section>
    </ListView>
  );
}

// /////////////////////////////////////////////////////////////////////
//                              TEASERS                               //
// /////////////////////////////////////////////////////////////////////

type TeaserProps = {
  biAction: ?() => void,
  data: TeaserDataType,
  isLazyloadImages?: boolean,
  gridArea: string,
  theme: Object,
};

const MainTeaserAreaTempS = `
"media media media"
". titleHeb ."
". titleAr ."
". footer ."
`;

const MainTeaserAreaTempL = `
"media media media media media"
". titleHeb sep titleAr ."
"footer footer footer footer footer"
`;

BilingualMainTeaser.defaultProps = { isLazyloadImages: true, biAction: null, };
export function BilingualMainTeaser({
  biAction,
  data,
  isLazyloadImages,
  theme,
}: TeaserProps): React.Node {
  const { css, } = useFela();

  const { arabicTeaser, hebrewTeaser, } = data;
  if (!(arabicTeaser && hebrewTeaser)) { return null; }

  return (
    <Teaser
      key={arabicTeaser.contentId}
      gridArea="main"
      areasTemplate={[
        { until: 's', value: MainTeaserAreaTempS, },
        { from: 's', value: MainTeaserAreaTempL, },
      ]}
      colTemplate={[
        { until: 's', value: '2rem 1fr 1rem', },
        { from: 's', value: '1rem 1fr 1px 1fr 1rem', },
      ]}
      rowGap={[ { until: 's', value: '2rem', }, { from: 's', value: '2rem', }, ]}
      colGap={[ { from: 's', value: '2rem', }, ]}
      rowTemplate={[ { from: 's', value: 'auto 1fr  auto', }, ]}
    >
      <TeaserMedia data={arabicTeaser} onClick={biAction}>
        <Image
          lazyLoad={isLazyloadImages}
          imgOptions={{
            ...getImageAssets({
              bps: theme.bps,
              aspect: 'headline',
              sizes: [
                { from: 'xl', size: '590px', },
                { from: 'l', size: '476px', },
                { from: 'm', size: '720px', },
                { from: 's', size: '552px', },
                { size: 'calc(100vw - 24px)', },
              ],
              widths: [ 720, 590, 552, 476, 390, 296, ],
            }),
          }}
          image={arabicTeaser.image}
        />
      </TeaserMedia>
      <TeaserHeader
        {...arabicTeaser}
        hasBlockLink={false}
        wrapperMiscStyles={{
          gridArea: 'titleHeb',
          // setting display to grid in all TeaserHeaders so that the anchor would apply to the entire box
          display: 'grid',
        }}
        typeScale={[
          { until: 's', value: 1, },
          { from: 's', until: 'l', value: 2, },
          { from: 'l', value: 1, },
        ]}
        onClick={biAction}
      />
      {hebrewTeaser ? (
        <>
          <div
            className={css({
              gridArea: 'sep',
              backgroundColor: theme.color('neutral', '-4'),
              ...theme.mq({ until: 's', }, { display: 'none', }),
            })}
            aria-hidden
          />
          <TeaserHeader
            {...hebrewTeaser}
            hasBlockLink={false}
            wrapperMiscStyles={{
              gridArea: 'titleAr',
              display: 'grid',
            }}
            typeScale={[
              { until: 's', value: 1, },
              { from: 's', until: 'l', value: 2, },
              { from: 'l', value: 1, },
            ]}
            onClick={biAction}
          />
        </>
      ) : null}
      <TeaserFooter
        data={arabicTeaser}
        showAuthor
        showTime
        showArabicAuthor
        miscStyles={{
          textAlign: [ { from: 's', value: 'center', }, ],
          paddingBottom: '1rem',
        }}
      />
    </Teaser>
  );
}

const mediaObjTeaserAreas = `
  ".    .   .   . media"
  ". titleHeb sep titleAr media"
  ". footer footer footer media"
  ". .      .      .      media"
`;

const MainTeaserAreaTempM = `
"media .        .   .       ."
"media titleHeb sep titleAr ."
"media footer footer footer ."
`;

const TeaserAreasNotranslatedArticles = `
  ".  .        .        .        media"
  ".  titleHeb titleHeb titleHeb media"
  ".  footer   footer   footer   media"
  ".  .        .        .        media"
`;

BilingualTwoUpTeaser.defaultProps = teaserDefaultProps;
function BilingualTwoUpTeaser({
  data,
  gridArea,
  isLazyloadImages,
  biAction,
  theme,
}: TeaserProps): React.Node {
  const { css, } = useFela();

  const { arabicTeaser, hebrewTeaser, } = data;
  if (!(arabicTeaser && hebrewTeaser)) { return null; }

  return (
    <Teaser
      data={arabicTeaser}
      key={arabicTeaser.contentId}
      gridArea={gridArea}
      areasTemplate={[
        { until: 's', value: MainTeaserAreaTempS, },
        { from: 's', until: 'l', value: MainTeaserAreaTempM, },
        {
          from: 's',
          value: hebrewTeaser ? mediaObjTeaserAreas : TeaserAreasNotranslatedArticles,
        },
      ]}
      gridGap={[ { from: 's', until: 'l', value: '2rem', }, { from: 's', value: '1rem', }, ]}
      rowGap={[ { until: 's', value: '2rem', }, ]}
      colTemplate={[
        { until: 's', value: '2rem 1fr 2rem', },
        { from: 's', until: 'l', value: '2fr 2fr 1px 2fr 0', },
        { from: 'l', value: '0 1fr 1px 1fr 1fr', },
      ]}
      rowTemplate={[ { from: 's', value: '0 1fr auto', }, ]}
      gutter={0}
      onClick={biAction}
    >
      <TeaserMedia data={arabicTeaser} onClick={biAction}>
        <Picture
          lazyLoad={isLazyloadImages}
          {...getPictureAssets({
            bps: theme.bps,
            imgData: arabicTeaser.image,
            defaultImgOptions: {
              sizes: 'calc(100vw - 24px)',
              aspect: 'headline',
              widths: [ 560, 476, 390, 296, ],
            },
            sources: [
              {
                aspect: 'regular',
                from: 's',
                sizes: [
                  { from: 'xl', size: '187px', },
                  { from: 'l', size: '151px', },
                  { from: 'm', size: '224px', },
                  { from: 's', size: '168px', },
                ],
                widths: [ 720, 590, 552, 476, ],
              },
            ],
          })}
        />
      </TeaserMedia>
      <TeaserHeader
        {...arabicTeaser}
        hasBlockLink={false}
        wrapperMiscStyles={{
          gridArea: 'titleHeb',
          display: 'grid',
        }}
        // typeScale={[ { from: 's', until: 'xl', value: 1, }, ]}
        typeScale={[ { until: 'l', value: 0, }, { from: 'l', value: -1, }, ]}
        onClick={biAction}
      />
      {hebrewTeaser ? (
        <>
          <div
            className={css({
              gridArea: 'sep',
              backgroundColor: theme.color('neutral', '-4'),
              ...theme.mq({ until: 's', }, { display: 'none', }),
            })}
            aria-hidden
          />
          <TeaserHeader
            {...hebrewTeaser}
            hasBlockLink={false}
            wrapperMiscStyles={{
              gridArea: 'titleAr',
              display: 'grid',
            }}
            typeScale={[ { until: 'l', value: 0, }, { from: 'l', value: -1, }, ]}
            // typeScale={[ { from: 's', until: 'xl', value: 1, }, ]}
            onClick={biAction}
          />
        </>
      ) : null}
      <TeaserFooter
        data={arabicTeaser}
        miscStyles={{
          paddingTop: [ { until: 's', value: '1rem', }, ],
          paddingBottom: [ { until: 'l', value: '1rem', }, ],
          textAlign: [ { from: 's', value: 'center', }, ],
        }}
        showAuthor
        showRank
        showArabicAuthor
      />
    </Teaser>
  );
}

const TxtObjTeaserAreas = `
  ". .      .      .      ."
  ". titleHeb sep titleAr ."
  ". footer footer footer ."
  ". .      .      .      ."
`;
const TxtstackedTeaserAreas = `
  ".     titleHeb  ."
  ".     titleAr   ."
  ".     footer    ."
`;

BilingualTextualTeaser.defaultProps = teaserDefaultProps;
function BilingualTextualTeaser({ data, gridArea, biAction, theme, }: TeaserProps): React.Node {
  const { css, } = useFela();
  const { arabicTeaser, hebrewTeaser, } = data;
  if (!(arabicTeaser && hebrewTeaser)) { return null; }

  return (
    <Teaser
      data={arabicTeaser}
      key={arabicTeaser.contentId}
      gridArea={gridArea}
      areasTemplate={[
        { until: 's', value: TxtstackedTeaserAreas, },
        { from: 's', value: TxtObjTeaserAreas, },
      ]}
      onClick={biAction}
      rowTemplate={[ { from: 's', value: '0 1fr auto 0', }, ]}
      colTemplate={[
        { until: 's', value: '2rem 1fr 2rem', },
        { from: 's', value: '0 1fr 1px 1fr 0', },
      ]}
      gridGap={[ { from: 's', until: 'l', value: '2rem', }, { from: 's', value: '1rem', }, ]}
      rowGap={[ { until: 's', value: '2rem', }, ]}
    >
      <TeaserHeader
        {...arabicTeaser}
        hasBlockLink={false}
        typeScale={[ { until: 'l', value: 0, }, { from: 'l', value: -1, }, ]}
        // typeScale={[ { from: 's', until: 'xl', value: 1, }, ]}
        onClick={biAction}
        wrapperMiscStyles={{
          gridArea: 'titleHeb',
          paddingTop: [ { until: 's', value: '2rem', }, ],
          display: 'grid',
        }}
      />
      {hebrewTeaser ? (
        <>
          <div
            className={css({
              ...theme.mq({ until: 's', }, { display: 'none', }),
              gridArea: 'sep',
              backgroundColor: theme.color('neutral', '-4'),
            })}
            aria-hidden
          />
          <TeaserHeader
            {...hebrewTeaser}
            hasBlockLink={false}
            wrapperMiscStyles={{
              gridArea: 'titleAr',
              display: 'grid',
            }}
            typeScale={[ { until: 'l', value: 0, }, { from: 'l', value: -1, }, ]}
            onClick={biAction}
          />
        </>
      ) : null}
      <TeaserFooter
        miscStyles={{
          textAlign: [ { from: 's', value: 'center', }, ],
          paddingTop: [ { until: 's', value: '1rem', }, ],
          paddingBottom: [ { until: 's', value: '1rem', }, ],
        }}
        showAuthor
        showTime
        showArabicAuthor
        showRank
        data={arabicTeaser}
      />
    </Teaser>
  );
}
