// @flow
import * as React from 'react';
import { useInView, } from 'react-intersection-observer';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import Image from '../../../Image/Image';
import TeaserContent from '../../../TeaserContent/NewTeaserContent';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserFooter from '../../../TeaserFooter/TeaserFooter';
import getImageAssets from '../../../../utils/getImageAssets';

import { type TeaserDataType, } from '../../../../flowTypes/TeaserDataType';

type Props = {
  data: TeaserDataType,
  isLazyloadImages: boolean,
  biAction: ?() => void,
  biImpression: ?() => void,
  theme: Object,
};

const areasTemplate = [
  {
    until: 's',
    value: `
    "media media media"
    ".    content    ."
    ".    footer     ."
`,
  },
  {
    from: 's',
    value: `
    "media    .    ."
    "media content ."
    "media footer  ."
    "media    .    ."
`,
  },
];

const colsTemplate = [
  { until: 's', value: '0 1fr 0', },
  { from: 's', until: 'l', value: '33rem 1fr 0', },
  { from: 's', until: 'xl', value: '38rem 1fr 0', },
  { from: 'xl', value: '41rem 1fr 0', },
];

const rowsTemplate = [ { until: 's', value: 'auto 1fr auto', }, { from: 's', value: '0 1fr auto 0', }, ];

export default function AharoniTeaser({ data, isLazyloadImages, biAction, theme, biImpression, }: Props) {
  const [ isImpressionSent, setIsImpressionSent, ] = React.useState(false);
  const [ ref, inView, ] = useInView({ triggerOnce: true, threshold: 0.9, });
  React.useEffect(() => {
    if (inView && biImpression && !isImpressionSent) {
      biImpression();
      setIsImpressionSent(true);
    }
  }, [ biImpression, isImpressionSent, inView, ]);
  const image = data?.mobileImage || data?.image;

  return (
    <Teaser
      fwRef={ref}
      data={data}
      areasTemplate={areasTemplate}
      gridGap="1rem 2rem"
      colTemplate={colsTemplate}
      rowTemplate={rowsTemplate}
    >
      <TeaserMedia data={data} onClick={biAction}>
        <Image
          lazyLoad={isLazyloadImages}
          imgOptions={getImageAssets({
            bps: theme.bps,
            aspect: 'regular',
            sizes: [
              { from: 'xl', size: '284px', },
              { from: 'l', size: '228px', },
              { from: 's', size: '196px', },
              { size: '144px', },
            ],
            widths: [ 284, 228, 196, 144, ],
          })}
          image={image}
        />
      </TeaserMedia>
      <TeaserContent tagName="header">
        <TeaserHeader
          {...data}
          typeScale={[ { until: 's', value: -1, }, { from: 's', value: 1, }, ]}
          onClick={biAction}
        />
      </TeaserContent>
      <TeaserFooter data={data} showAuthor />
    </Teaser>
  );
}
