// @flow
import React from 'react';
import { useFela, } from 'react-fela';
import type { ClickTrackerBannerType, } from '../../flowTypes/ClickTrackerBannerType';
import type { ClickTrackerBannerWrapperType, } from '../../flowTypes/ClickTrackerBannerWrapperType';

import LayoutContainer from '../PageLayout/LayoutContainer';
import ClickTrackerWrapper from './ClickTrackerWrapper';
import HtzLink from '../HtzLink/HtzLink';
import getImageAssets from '../../utils/getImageAssets';
import Image from '../Image/Image';

export default function WidePageClickTracker(props: ClickTrackerBannerWrapperType) {
  const { theme, } = useFela();
  return (
    <LayoutContainer
      bgc="transparent"
      miscStyles={{
        display: [ { until: 'l', value: 'none', }, ],
        paddingInlineStart: [ { from: 's', value: '4rem', }, { until: 's', value: '2rem', }, ],
        paddingInlineEnd: [ { from: 's', value: '4rem', }, { until: 's', value: '2rem', }, ],
        marginTop: [ { until: 's', value: '6rem', }, { from: 's', value: '8rem', }, ],
      }}
    >
      <ClickTrackerWrapper
        {...props}
        render={(banner: ClickTrackerBannerType) => {
          const { image, link, } = banner;
          return (
            <HtzLink href={link}>
              <Image
                hasWrapper={false}
                image={image}
                imgOptions={getImageAssets({
                  bps: theme.bps,
                  aspect: 'full',
                  sizes: [
                    { from: 'xl', size: '1207px', },
                    { from: 'l', size: '976px', },

                    { size: 'calc(100vw - 4rem)', },
                  ],
                  widths: [ 980, 1080, 1207, 1400, ],
                })}
              />
            </HtzLink>
          );
        }}
      />
    </LayoutContainer>
  );
}
