// @flow
import React from 'react';
import { useFela, } from 'react-fela';
import type { ListViewProps, } from '../../../../flowTypes/ListViewProps';
import ListView from '../../../ListView/NewListView';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';
import Section from '../../../AutoLevels/Section';
import Broadcast3Teaser from './Broadcast3Teaser';
import setBiAction from '../../../../utils/setBiAction.js';
import setBiImpression from '../../../../utils/setBiImpression';

Broadcast3View.defaultProps = {
  biAction: null,
  gaAction: null,
  sectionId: null,
  isLazyloadImages: true,
};


export default function Broadcast3View({
  list,
  isLazyloadImages,
  biAction,
  biImpression,
}: ListViewProps) {
  const { theme, } = useFela();
  const { items, title, } = list;

  if (!items) return null;

  return (
    <ListView
      miscStyles={{
        display: [
          { until: 'l', value: 'none', },
        ],
      }}
      marginTop={[ { from: 'l', value: '0rem', }, ]}
      padding={null}
    >
      {title ? (
        <ListViewHeader
          title={title}
          isSticky
          miscStyles={{
            marginBottom: '3rem',
          }}
        />
      ) : null}
      <Section isFragment>
        {items.map((item, i) => (
          <Broadcast3Teaser
            key={item.contentId}
            item={item}
            isLazyloadImages={isLazyloadImages}
            biAction={setBiAction(i, item, biAction)}
            biImpression={setBiImpression(i, item, biImpression)}
            theme={theme}
          />
        ))}
      </Section>
    </ListView>
  );
}
